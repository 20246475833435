import { inject, Injectable } from '@angular/core';
import {
  ONBOARDING_STEPS_DEFINITION_CHAPTER,
  ONBOARDING_STEPS_DEFINITION_DIRECT,
  ONBOARDING_STEPS_DEFINITION_MARSHFIELD,
  ONBOARDING_STEPS_DEFINITION_MODA,
  ONBOARDING_STEPS_DEFINITION_OPTUM,
  ONBOARDING_STEPS_DEFINITION_PROGRAM_PAGE,
  ONBOARDING_STEPS_DEFINITION_UHC_SAML,
} from '../pages/onboarding-v2/onboarding-steps-definition';
import { ExperimentService } from '../utils/experiment.service';
import { StorageService } from '../utils/storage.service';

export interface OnboardingVariant {
  name: string;
  route: string[];
}

const ONBOARDING_EXPERIMENT_KEY = 'web-onboarding';

export const WEB_ONBOARDING_OPTUM_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_optum_variant',
  route: ONBOARDING_STEPS_DEFINITION_OPTUM,
};

export const WEB_ONBOARDING_MARSHFIELD: OnboardingVariant = {
  name: 'web_onboarding_marshfield_variant',
  route: ONBOARDING_STEPS_DEFINITION_MARSHFIELD,
};

export const WEB_ONBOARDING_CHAPTER: OnboardingVariant = {
  name: 'web_onboarding_chapter_variant',
  route: ONBOARDING_STEPS_DEFINITION_CHAPTER,
};

const WEB_ONBOARDING_DIRECT_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_direct',
  route: ONBOARDING_STEPS_DEFINITION_DIRECT,
};

const WEB_ONBOARDING_PROGRAM_PAGE_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_program_page',
  route: ONBOARDING_STEPS_DEFINITION_PROGRAM_PAGE,
};

export const WEB_ONBOARDING_MODA_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_moda_variant',
  route: ONBOARDING_STEPS_DEFINITION_MODA,
};

export const WEB_ONBOARDING_UHC_SAML_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_uhc_saml_variant',
  route: ONBOARDING_STEPS_DEFINITION_UHC_SAML,
};

@Injectable({
  providedIn: 'root',
})
export class OnboardingExperimentService {
  storage = inject(StorageService);
  experimentService = inject(ExperimentService);

  async startOnboardingExperiment(forceVariants?: string[]): Promise<OnboardingVariant> {
    try {
      const variant = await this.experimentService.startExperiment(
        ONBOARDING_EXPERIMENT_KEY,
        [WEB_ONBOARDING_PROGRAM_PAGE_VARIANT.name],
        forceVariants
      );

      return this.selectOnboardingVariant(variant);
    } catch (error) {
      console.error('Error starting onboarding experiment:', error);

      return WEB_ONBOARDING_PROGRAM_PAGE_VARIANT;
    }
  }

  private selectOnboardingVariant(variant: string): OnboardingVariant {
    const variantMap: Record<string, OnboardingVariant> = {
      [WEB_ONBOARDING_OPTUM_VARIANT.name]: WEB_ONBOARDING_OPTUM_VARIANT,
      [WEB_ONBOARDING_MARSHFIELD.name]: WEB_ONBOARDING_MARSHFIELD,
      [WEB_ONBOARDING_CHAPTER.name]: WEB_ONBOARDING_CHAPTER,
      [WEB_ONBOARDING_MODA_VARIANT.name]: WEB_ONBOARDING_MODA_VARIANT,
      [WEB_ONBOARDING_UHC_SAML_VARIANT.name]: WEB_ONBOARDING_UHC_SAML_VARIANT,
      [WEB_ONBOARDING_DIRECT_VARIANT.name]: WEB_ONBOARDING_DIRECT_VARIANT,
      [WEB_ONBOARDING_PROGRAM_PAGE_VARIANT.name]: WEB_ONBOARDING_PROGRAM_PAGE_VARIANT,
    };

    const selectedVariant = variantMap[variant] || WEB_ONBOARDING_PROGRAM_PAGE_VARIANT;
    ONBOARDING_SELECTED_VARIANT = selectedVariant;

    return selectedVariant;
  }
}

export let ONBOARDING_SELECTED_VARIANT: OnboardingVariant;
